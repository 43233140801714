import React from 'react';
import Image from '../Image';
import './CalculatorBlock.scss';

const CalculatorBlock = () => (
  <div className="section-block-calculate">
    <div className="block-calculate-wrap">
      <div className="image block-calculate-img-big">
        <Image src="/images/calculator-block-big" />
      </div>
      <div className="image block-calculate-img">
        <Image src="/images/calculator-block" />
      </div>
      <div className="image block-calculate-img-min">
        <Image src="/images/calculator-block-min" />
      </div>
      <div className="block-calculate-content">
        <div className="block-calculate-title">
          Have you ever wondered how your daily choices affect the planet?
        </div>
        <div className="block-calculate-text">
          Our free carbon footprint calculator helps you understand your impact
          and empowers you to make a difference. Every small change counts –
          calculate your footprint today.
        </div>
        <div className="block-calculate-btn">
          <a
            href="https://co2calc.geniusee.com/#car"
            className="btn-calculate"
            rel="nofollow"
            target="_blank"
          >
            Calculate
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default CalculatorBlock;
