import React, { useState, useCallback, useMemo } from 'react';
import './Location.scss';
import Image from '../Image';
import { sendEmailIntro } from '../../api/sendEmailIntro';
import { budgetArr, industryArr } from './defaultData';

const Location = () => {
  const [industry, setIndustry] = useState('');
  const [budget, setBudget] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [checkBox, setCheckBox] = useState(false);
  const [isOpenBudget, setIsOpenBudget] = useState(false);
  const [isOpenIndustry, setIsOpenIndustry] = useState(false);

  const handleDetailsBudget = useCallback(
    event => {
      event.preventDefault();
      setIsOpenBudget(!isOpenBudget);
    },
    [isOpenBudget]
  );

  const handleDetailsIndustry = useCallback(
    event => {
      event.preventDefault();
      setIsOpenIndustry(!isOpenIndustry);
    },
    [isOpenIndustry]
  );

  const handleText = useCallback((text, setText) => {
    setText(text);
  }, []);

  const handleSelect = useCallback((value, setValue) => {
    console.log(value, 'value');
    setValue(value);
  }, []);

  const validateCheckboxContactForm = useCallback(() => {
    setCheckBox(!checkBox);
  }, [checkBox]);

  const isDisable = useMemo(() => {
    if (budget && industry && checkBox && name && phone && email) {
      return false;
    }
    return true;
  }, [budget, checkBox, email, industry, name, phone]);

  const handleSendEmail = useCallback(async () => {
    try {
      await sendEmailIntro({
        name,
        phone,
        email,
        industry,
        expected_budget: budget,
        message
      });
    } catch (error) {
      console.log(error);
    }
  }, [budget, email, industry, message, name, phone]);

  return (
    <section id="contact" className="contact">
      <div className="col-11">
        <div className="contact-form-grid">
          <div className="form-wrapper">
            <div className="form-upd-container">
              <div id="formWrap">
                <div className="contact__front-side">
                  <h2 className="font--h2-big">Let's talk!</h2>
                  <div className="form">
                    <div
                      className="form_error_message font-body-3"
                      id="form_error_message"
                    >
                      <img
                        src="/storage/app/media/warning.svg"
                        width="25"
                        height="31"
                        alt="warning"
                      />
                      This field is required
                    </div>
                    <fieldset className="form-fieldset">
                      <div>
                        <div>
                          <label className="form-label">
                            <div className="required-input-wrapper">
                              <input
                                id="main_form_name"
                                type="text"
                                name="name"
                                className="form-input"
                                required=""
                                placeholder="Name*"
                                onChange={event =>
                                  handleText(event.target.value, setName)
                                }
                              />
                            </div>
                            <div className="error_message"></div>
                          </label>
                        </div>
                      </div>
                      <div className="grid">
                        <div>
                          <label className="form-label">
                            <div className="required-input-wrapper">
                              <input
                                id="main_phone_number"
                                type="tel"
                                name="phone"
                                className="form-input"
                                required=""
                                placeholder="Phone*"
                                onChange={event =>
                                  handleText(event.target.value, setPhone)
                                }
                              />
                            </div>
                            <div className="error_message"></div>
                          </label>
                        </div>
                        <div>
                          <label className="form-label">
                            <div className="required-input-wrapper">
                              <input
                                id="main_form_email"
                                type="text"
                                name="email"
                                className="form-input"
                                required=""
                                placeholder="Email*"
                                onChange={event =>
                                  handleText(event.target.value, setEmail)
                                }
                              />
                            </div>
                            <div className="error_message"></div>
                          </label>
                        </div>
                      </div>
                      <div className="grid dropdowns">
                        <div>
                          <details
                            id="industry_select"
                            className="select_container"
                            aria-haspopup="listbox"
                            open={isOpenIndustry}
                            onClick={handleDetailsIndustry}
                          >
                            <summary id="industry_summary">
                              {industry || 'Industry*'}
                            </summary>
                            <div className="select" role="listbox">
                              {industryArr.map(x => (
                                <label
                                  className="select__option"
                                  key={x.key}
                                  onClick={() =>
                                    handleSelect(x.label, setIndustry)
                                  }
                                >
                                  <input
                                    type="radio"
                                    name="industry"
                                    value={x.label}
                                    role="option"
                                  />
                                  {x.label}
                                </label>
                              ))}
                            </div>
                          </details>
                          <div className="error_message options_error"></div>
                        </div>
                        <div>
                          <details
                            id="budget_select"
                            className="select_container"
                            aria-haspopup="listbox"
                            open={isOpenBudget}
                            onClick={handleDetailsBudget}
                          >
                            <summary id="budget_summary">
                              {budget || 'Expected Budget*'}
                            </summary>
                            <div className="select" role="listbox">
                              {budgetArr.map(x => (
                                <label
                                  key={x.key}
                                  className="select__option"
                                  onClick={() =>
                                    handleSelect(x.label, setBudget)
                                  }
                                >
                                  <input
                                    type="radio"
                                    name="expected_budget"
                                    value={x.label}
                                    role="option"
                                  />
                                  {x.label}
                                </label>
                              ))}
                            </div>
                          </details>
                          <div
                            className="error_message options_error"
                            style={{ bottom: '-18px' }}
                          ></div>
                        </div>
                      </div>
                      <div className="message">
                        <div>
                          <label className="form-label">
                            <textarea
                              name="message"
                              placeholder="Message"
                              className="form-textarea"
                              onChange={event =>
                                handleText(event.target.value, setMessage)
                              }
                            ></textarea>
                            <div className="error_message"></div>
                            <p className="form-rc-message">
                              This site is protected by reCAPTCHA and the Google{' '}
                              <a
                                href="https://policies.google.com/privacy"
                                rel="nofollow"
                                className="purple-link"
                                target="_blank"
                              >
                                Privacy Policy
                              </a>{' '}
                              <a
                                href="https://policies.google.com/terms"
                                rel="nofollow"
                                className="purple-link"
                                target="_blank"
                              >
                                Terms of Service
                              </a>
                            </p>
                          </label>
                        </div>
                      </div>
                    </fieldset>
                    <div className="consent">
                      <input
                        id="contact-privacy-consent"
                        name="privacy-consent"
                        type="checkbox"
                        value={checkBox}
                        onChange={() => validateCheckboxContactForm()}
                      />
                      <label htmlFor="contact-privacy-consent">
                        I agree that my personal information will be processed
                        and stored by Geniusee.
                      </label>
                    </div>
                    <div className="form-btn-wrap">
                      <button
                        type="submit"
                        id="submit"
                        className="btn-purple"
                        disabled={isDisable}
                        onClick={handleSendEmail}
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
                <div className="contact__back-side" id="thnx">
                  {/* <lottie-player src="https://geniusee.com/themes/geniusee/assets/compiled/css/mail-animation.json" background="transparent" speed="1" style="width: 200px; height: 200px;"></lottie-player> */}
                  <div className="thanks-text">
                    <span className="thanks-main">Thanks!</span>
                    <span className="thanks-contact">
                      We will contact you soon.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ceo-wrapper">
            <div className="ceo-photo">
              <img
                src="https://ik.imagekit.io/k0jemnbco/Form/180803-0079-depositphotos-bgremover_1.webp"
                className=" lazyloaded"
                alt="Taras"
                width="419"
                height="740"
                loading="lazy"
              />
            </div>
            <div className="ceo-contacts">
              <div className="contact-text">
                <h3 className="font--h4">
                  Taras Tymoshchuk<span className="contact-text-koma">,</span>
                  <span className="contact-text-ceo"> CEO</span>
                </h3>
              </div>
              <div className="contact-box">
                <div className="contact-box-item">
                  <div className="contact-box-item-img-wrapper">
                    <Image
                      src="/images/mail"
                      alt="Mail"
                      loading="lazy"
                      width="20"
                      height="16"
                    />
                  </div>
                  <a
                    className="font--16"
                    href="mailto:info@geniusee.com"
                    rel="nofollow"
                  >
                    info@geniusee.com
                  </a>
                </div>
                <div className="contact-box-item">
                  <div className="contact-box-item-img-wrapper">
                    <Image
                      src="/images/mob"
                      alt="Phone"
                      loading="lazy"
                      width="14"
                      height="22"
                    />
                  </div>
                  <a
                    className="font--16"
                    href="tel:+1 512 333 1220"
                    rel="nofollow"
                  >
                    +1 512 333 1220
                  </a>
                </div>
                <div className="contact-box-item">
                  <div className="contact-box-item-img-wrapper">
                    <Image
                      src="/images/skype"
                      alt="Skype"
                      loading="lazy"
                      width="19"
                      height="19"
                    />
                  </div>
                  <a
                    className="font--16"
                    href="skype:taras.tymoshchuk?chat"
                    rel="nofollow"
                  >
                    taras.tymoshchuk
                  </a>
                </div>
                <div className="contact-box-item">
                  <div className="contact-box-item-img-wrapper">
                    <Image
                      src="/images/LinkedinOutlined"
                      alt="Linkedin"
                      loading="lazy"
                      width="18"
                      height="18"
                    />
                  </div>
                  <a
                    className="font--16"
                    href="https://www.linkedin.com/in/taras-tymoshchuk-3071243b/"
                    rel="nofollow"
                    target="_blank"
                  >
                    Taras Tymoshchuk
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-11 contact-locations">
        <a
          className="location-item"
          href="https://www.google.com/maps/place/651+N+Broad+St,+Middletown,+DE+19709,+USA/@39.4558954,-75.7196372,17z/data=!3m1!4b1!4m5!3m4!1s0x89c7a079b74b9215:0x828b14967b713bf9!8m2!3d39.4558954!4d-75.7174432"
          rel="nofollow"
        >
          <div className="location-info">
            <div className="location-name">
              <Image src="/images/local-us" alt="Location" loading="lazy" />
              <span className="font--h4">Middletown</span>
            </div>
            <p className="font--16">
              651 N Broad St, Suite 206,
              <br /> Middletown, DE 19709, USA
            </p>
          </div>
        </a>
        <a
          className="location-item"
          href="https://www.google.com/maps/place/Adama+Branickiego+21A%2FLOK.+U3,+02-972+Warszawa,+Poland/@52.1541222,21.0714514,17z/data=!3m1!4b1!4m5!3m4!1s0x47192d68d686fe15:0x1265bcce5fbcdb8d!8m2!3d52.1541189!4d21.0736401"
          rel="nofollow"
        >
          <div className="location-info">
            <div className="location-name">
              <Image src="/images/local-pl" alt="Location" loading="lazy" />
              <span className="font--h4">Warsaw</span>
            </div>
            <p className="font--16">
              Ul. Adama Branickiego 21/U3,
              <br /> Warsaw 02-972, Poland
            </p>
          </div>
        </a>
        <a
          className="location-item"
          href="https://www.google.com/maps/place/%D0%9E%D1%84%D0%B8%D1%81%D0%BD%D1%8B%D0%B9+%D0%A6%D0%B5%D0%BD%D1%82%D1%80+Y4/@50.4665562,30.5063468,17z/data=!3m1!4b1!4m5!3m4!1s0x40d4cf6a63c041c1:0x42c7765fd758a24c!8m2!3d50.4665562!4d30.5085407"
          rel="nofollow"
        >
          <div className="location-info">
            <div className="location-name">
              <Image src="/images/local-ua" alt="Location" loading="lazy" />
              <span className="font--h4">Kyiv</span>
            </div>
            <p className="font--16">
              BC Y4, Yaroslavs'kyi Lane 4,
              <br />
              Kyiv 04071, Ukraine
            </p>
          </div>
        </a>
        <a
          className="location-item"
          href="https://www.google.com/maps/place/Fedkovycha+St,+60%D0%B0,+L'viv,+L'vivs'ka+oblast,+79000/@49.8331622,23.9945114,17z/data=!3m1!4b1!4m5!3m4!1s0x473add80e79f5903:0x9f577077202b3380!8m2!3d49.8331622!4d23.9967054"
          rel="nofollow"
        >
          <div className="location-info">
            <div className="location-name">
              <Image src="/images/local-ua" alt="Location" loading="lazy" />
              <span className="font--h4">Lviv</span>
            </div>
            <p className="font--16">
              BC Technopark, Fedkovycha St
              <br /> 60a, Lviv 79000, Ukraine
            </p>
          </div>
        </a>
      </div>
    </section>
  );
};

export default Location;
