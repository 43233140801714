import Axios from 'axios';

export const sendEmailIntro = async ({
  name,
  phone,
  email,
  industry,
  expected_budget,
  message
}) => {
  try {
    const res = await Axios(
      `${process.env.REACT_APP_BACKEND_HTTP_URL}/send_email_intro`,
      {
        method: 'POST',
        data: JSON.stringify({
          name,
          phone,
          email,
          industry,
          expected_budget,
          message
        })
      }
    );
    let body = await res.json();
    return body;
  } catch (error) {
    return false;
  }
};
