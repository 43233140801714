import React, { useCallback, useEffect, useState } from 'react';
import Image from '../Image';
import './AIQuestionsCard.scss';
import { Input, MenuItem, Select, ListSubheader } from '@material-ui/core';

const AIQuestionsCard = ({
  text,
  title,
  icon,
  note,
  onChangeText,
  index,
  options,
  placeholder,
  isMultiple,
  customRolePlaceholder
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [customOption, setCustomOption] = useState('');

  useEffect(() => {
    if (!text.length) {
      setSelectedItems([]);
      setCustomOption('');
    }
  }, [text]);

  const handleSingleSelectClick = useCallback(
    event => {
      onChangeText(index, event.target.value);
      setIsOpen(false);
    },
    [index, onChangeText]
  );

  const handleDetails = useCallback(
    event => {
      event.preventDefault();
      setIsOpen(!isOpen);
    },
    [isOpen]
  );

  const handleMultiSelecton = (e, index) => {
    const _value = e.target.value.filter(x => x);
    setSelectedItems(_value);
    onChangeText(index, _value.map(x => x.trim()).join(','), _value);
  };

  const handleChangeCustomOption = (e, prevValue, index) => {
    const _value = e.currentTarget.value;
    setCustomOption(_value);

    let updatedSelectedItems = [...selectedItems];
    if (updatedSelectedItems.includes(prevValue)) {
      updatedSelectedItems = updatedSelectedItems.map(
        item => item === prevValue && _value
      );
    } else {
      updatedSelectedItems.push(_value);
    }
    onChangeText(index, updatedSelectedItems.join(', '), updatedSelectedItems);
  };

  const getValueWithCustomOption = (selectedItems, customOption) => {
    if (selectedItems.length > 0 && customOption) {
      return `${selectedItems.map(x => x.trim()).join(', ')}${', ' +
        customOption.trim()}`;
    } else if (selectedItems.length > 0) {
      return selectedItems.map(x => x.trim()).join(', ');
    } else {
      return customOption;
    }
  };

  return (
    <div className={`AIQuestionsCard-card`}>
      <div className="body">
        <Image src={`/images/${icon}`} className="icon" />
        <h3 className="title">{title}</h3>
        <p className="note">{note}</p>
      </div>
      {isMultiple && options ? (
        <Select
          multiple
          value={selectedItems.concat('')}
          onChange={e => handleMultiSelecton(e, index)}
          placeholder={placeholder}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
          renderValue={() => (
            <div className="render-value">
              {getValueWithCustomOption(selectedItems, customOption)}
            </div>
          )}
        >
          {options.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
          <ListSubheader>
            <Input
              style={{ width: '100%' }}
              value={customOption}
              onChange={e => handleChangeCustomOption(e, customOption, index)}
              placeholder={customRolePlaceholder}
              required
              onKeyDown={e => e.stopPropagation()}
            />
          </ListSubheader>
        </Select>
      ) : options ? (
        <div>
          <details
            id={
              index === 1
                ? 'industry_select'
                : `${title
                    .toLowerCase()
                    .split(' ')
                    .join('')
                    .trim()}_select`
            }
            className="select_container"
            aria-haspopup="listbox"
            open={isOpen}
            onClick={event => handleDetails(event)}
          >
            <summary
              id={
                index === 1
                  ? 'industry_summary'
                  : `${title
                      .toLowerCase()
                      .split(' ')
                      .join('')
                      .trim()}_summary`
              }
            >
              {text ? text : placeholder}
            </summary>
            <div className="select" role="listbox">
              {options.map(x => {
                return (
                  <label
                    className="select__option"
                    key={x}
                    onClick={() => onChangeText(index, x)}
                  >
                    <input
                      type="radio"
                      name="industry"
                      value={x}
                      role="option"
                      onClick={() => onChangeText(x)}
                    />
                    {x}
                  </label>
                );
              })}
            </div>
          </details>
          <div className="error_message options_error"></div>
        </div>
      ) : (
        <Input
          value={text}
          onChange={handleSingleSelectClick}
          placeholder={placeholder}
          required
          className="AIQuestionsCard-input"
          disableUnderline={true}
        />
      )}
    </div>
  );
};

export default AIQuestionsCard;
