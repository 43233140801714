export const budgetArr = [
  {
    key: '<30k',
    label: '<30k'
  },
  {
    key: '30k-100k',
    label: '30k-100k'
  },
  {
    key: '100k-200k',
    label: '100k-200k'
  },
  {
    key: '>200k',
    label: '>200k'
  },
  {
    key: 'N\\A',
    label: 'N\\A'
  }
];

export const industryArr = [
  {
    key: 'Finance',
    label: 'Finance'
  },
  {
    key: 'Agriculture',
    label: 'Agriculture'
  },
  {
    key: 'Education',
    label: 'Education'
  },
  {
    key: 'Healthcare',
    label: 'Healthcare'
  },
  {
    key: 'E-commerce',
    label: 'E-commerce'
  },
  {
    key: 'Biotechnology',
    label: 'Biotechnology'
  },
  {
    key: 'Logistic & Transportation',
    label: 'Logistic & Transportation'
  },
  {
    key: 'Sports & Games',
    label: 'Sports & Games'
  },
  {
    key: 'Construction',
    label: 'Construction'
  },
  {
    key: 'Food & Supplements',
    label: 'Food & Supplements'
  },
  {
    key: 'Consulting',
    label: 'Consulting'
  },
  {
    key: 'Legal',
    label: 'Legal'
  },
  {
    key: 'Marketing',
    label: 'Marketing'
  },
  {
    key: 'Travel & Hospitality',
    label: 'Travel & Hospitality'
  },
  {
    key: 'Technology',
    label: 'Technology'
  },
  {
    key: 'setIndustry',
    label: 'setIndustry'
  }
];
